import React, { useState, useEffect } from 'react';
import './Admin.css'; // We'll create this file later for styling
import { ethers } from 'ethers';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Admin() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState('');
  const [pendingClaims, setPendingClaims] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedClaims, setSelectedClaims] = useState({});

  useEffect(() => {
    if (isLoggedIn) {
      fetchPendingClaims();
    } else {
      setLoading(false);
    }
  }, [isLoggedIn]);

  const handleLogin = (e) => {
    e.preventDefault();
    // Replace 'admin123' with your desired password
    if (password === 'admin123') {
      setIsLoggedIn(true);
    } else {
      setError('Incorrect password');
    }
  };

  const fetchPendingClaims = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pending_claims`);
      if (!response.ok) {
        throw new Error('Failed to fetch pending claims');
      }
      const data = await response.json();
      setPendingClaims(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleSelectAll = (e) => {
    const newSelectedClaims = {};
    pendingClaims.forEach((claim, index) => {
      newSelectedClaims[index] = e.target.checked;
    });
    setSelectedClaims(newSelectedClaims);
  };

  const handleSelectClaim = (index) => {
    setSelectedClaims(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handlePay = async () => {
    const selectedClaimsData = pendingClaims.filter((_, index) => selectedClaims[index]);
    
    if (selectedClaimsData.length === 0) {
      alert("No claims selected");
      return;
    }

    // Check if MetaMask is installed
    if (typeof window.ethereum === 'undefined') {
      alert('Please install MetaMask to process payments.');
      return;
    }

    const tokenAddress = '0x6e4E8FCaFcF48F7eD500493647D07E780A6bf78f';
    const tokenABI = [
      "function transfer(address to, uint256 amount) returns (bool)",
      "function balanceOf(address account) view returns (uint256)",
      "function decimals() view returns (uint8)"
    ];

    try {
      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);

      // Get token decimals
      const decimals = await tokenContract.decimals();

      for (const claim of selectedClaimsData) {
        const amount = ethers.parseUnits(claim.amount.toString(), decimals);
        
        // Create the transaction
        const transaction = await tokenContract.transfer(claim.evmAddress, amount);
        
        // Open MetaMask for user to confirm the transaction
        console.log("Processing payment for:", claim);
        await transaction.wait(); // Wait for the transaction to be mined
      }

      alert("Payments processed successfully!");
      // After successful payment, refresh the pending claims
      await fetchPendingClaims();
    } catch (err) {
      console.error("Payment failed:", err);
      alert("Payment failed. Please try again.");
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="Admin-login">
        <h2>Admin Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            required
          />
          <button type="submit">Login</button>
        </form>
        {error && <p className="error">{error}</p>}
      </div>
    );
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const allSelected = pendingClaims.length > 0 && 
    Object.values(selectedClaims).filter(Boolean).length === pendingClaims.length;

  return (
    <div className="Admin">
      <h1>Admin Dashboard - Pending Claims</h1>
      <button onClick={() => setIsLoggedIn(false)} className="logout-button">Logout</button>
      <table className="claims-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Substrate Address</th>
            <th>EVM Address</th>
            <th>Claim Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {pendingClaims.map((claim, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedClaims[index] || false}
                  onChange={() => handleSelectClaim(index)}
                />
              </td>
              <td>{claim.substrateAddress}</td>
              <td>{claim.evmAddress}</td>
              <td>{claim.amount ? claim.amount : 'N/A'}</td>
              <td>{claim.status ? 'Paid' : 'Pending'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="admin-controls">
        <div className="selected-count">
          Selected: {Object.values(selectedClaims).filter(Boolean).length}
        </div>
        <button onClick={handleSelectAll}>
          {allSelected ? 'Deselect All' : 'Select All'}
        </button>
        <button onClick={handlePay} className="pay-button">Pay Selected Claims</button>
      </div>
    </div>
  );
}

export default Admin;
