import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import Admin from './Admin'; // Import the Admin component
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
        </Routes>

        <footer className="App-footer">
          <p>
            Contact <a href="mailto:wallet@bitgreen.org">wallet@bitgreen.org</a>{' '}
            for support.
          </p>
          <p>© 2024 Bitgreen. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
